import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  Button,
} from "@material-ui/core";
import { PriceSection } from "./styled";
import { Link as ScrollLink } from "react-scroll";
const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    paddingLeft: theme.spacing(1),
    borderWidth: 8,
  },
  priceContent: {
    textAlign: "center",
    padding: theme.spacing(4),
    borderWidth: 2,
  },
  priceText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  priceButton: {
    marginTop: theme.spacing(3),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },
}));

export default function Price() {
  const classes = useStyles();
  return (
    <Fragment>
      <Box
        borderColor="primary.main"
        borderLeft={1}
        className={classes.sectionTitle}
      >
        <Typography variant="h2" gutterBottom>
          料金
        </Typography>
        <Typography variant="h6">
          定期利用（年間契約等）や1週間以上の利用を希望される場合は別途お見積もり致しますのでお問い合わせ下さい
        </Typography>
      </Box>
      <PriceSection>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={4}>
            <Paper
              className={classes.priceContent}
              border={1}
              variant="outlined"
            >
              <Typography variant="h3" color="primary" className={classes.text}>
                LITE
              </Typography>
              <div className={classes.priceText}>
                <Typography variant="h2" style={{ display: "inline-block" }}>
                  45,000
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    display: "inline-block",
                    marginLeft: 4,
                  }}
                >
                  /1day
                </Typography>
              </div>
              <Divider className={classes.divider} />
              <Typography variant="body1">
                機器のみのレンタルの
                <br />
                シンプルなプランです。
                <br />
                お試しで利用したい場合や、
                <br />
                1日限りのイベント等へ。
              </Typography>
              <Divider className={classes.divider} />
              <Typography variant="body1">審査結果用紙100枚付き。</Typography>
              <ScrollLink
                to="contact"
                smooth={true}
                duration={600}
                offset={-50}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.priceButton}
                >
                  申し込む
                </Button>
              </ScrollLink>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper
              className={classes.priceContent}
              border={1}
              variant="elevation"
              elevation={3}
            >
              <Typography variant="h3" color="primary" className={classes.text}>
                STANDARD
              </Typography>
              <div className={classes.priceText}>
                <Typography variant="h2" style={{ display: "inline-block" }}>
                  65,000
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    display: "inline-block",
                    marginLeft: 4,
                  }}
                >
                  /1day
                </Typography>
              </div>
              <Divider className={classes.divider} />
              <Typography variant="body1">
                専門職が同行します。
                <br />
                使い方に不安がある方や、
                <br />
                測定後に相談等を
                <br />
                ご希望される方へ。
              </Typography>
              <Divider className={classes.divider} />
              <Typography variant="body1">審査結果用紙100枚付き。</Typography>
              <Typography variant="body1">
                ※スタッフ対応は最大2時間+交通費別
              </Typography>
              <ScrollLink
                to="contact"
                smooth={true}
                duration={600}
                offset={-50}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.priceButton}
                >
                  申し込む
                </Button>
              </ScrollLink>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper
              className={classes.priceContent}
              border={1}
              variant="outlined"
            >
              <Typography variant="h3" color="primary" className={classes.text}>
                ADVANCED
              </Typography>
              <div className={classes.priceText}>
                <Typography variant="h2" style={{ display: "inline-block" }}>
                  90,000
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    display: "inline-block",
                    marginLeft: 4,
                  }}
                >
                  /3days
                </Typography>
              </div>
              <Divider className={classes.divider} />
              <Typography variant="body1">
                機器のみのレンタルです。
                <br />
                2日以上のイベント等で
                <br />
                利用される方に
                <br />
                お得なプランです。
              </Typography>
              <Divider className={classes.divider} />
              <Typography variant="body1">審査結果用紙200枚付き。</Typography>
              <ScrollLink
                to="contact"
                smooth={true}
                duration={600}
                offset={-50}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.priceButton}
                >
                  申し込む
                </Button>
              </ScrollLink>
            </Paper>
          </Grid>
        </Grid>
      </PriceSection>
    </Fragment>
  );
}
