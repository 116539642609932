import styled from "styled-components";
import { TextField as MaterialTextField } from "@material-ui/core";
export const FirstView = styled.div`
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column-reverse;
    h5 {
      text-align: left;
    }
  }
`;
export const AboutSection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column;
    h5 {
      text-align: left;
    }
  }
`;
export const AboutImage = styled.img`
  margin-top: 6vh;
  width: 30%;
  height: auto;
  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 3vh;
    width: 80%;
  }
`;
export const FlowImage = styled.img`
  margin-top: 6vh;
  width: 30%;
  height: auto;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
export const SectionDiv = styled.div`
  padding: 6vh 0;
`;

export const TopLeftSide = styled.div`
  text-align: center;
  @media (max-width: 768px) {
    margin-top: 8vh;
    width: 100%;
  }
`;
export const TopImage = styled.img`
  width: 40%;
  height: auto;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
export const WhatIsSection = styled.div`
  height: 50vh;
  text-align: center;
  /* position: relative; */
  /* background-color: #ffb36e; */
  border-radius: 8px;
  @media (max-width: 768px) {
    margin-top: 24px;
    h5 {
      text-align: left;
    }
  }
`;
export const WhatIsContent = styled.div``;
export const ServiceSection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin-top: 12vh;
  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column;
    p {
      text-align: left;
    }
  }
`;
export const PriceSection = styled.div`
  width: 70%;
  margin: 0 auto;
  margin-top: 12vh;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
export const InputFieldDiv = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 90%;
  }
`;
export const UseCaseSection = styled.div``;
export const FlowSection = styled.div`
  text-align: center;
`;
export const ContactSection = styled.div`
  margin-top: 12vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const TextField = styled(MaterialTextField)`
  margin-top: 2px;
  margin-bottom: 8px;
`;
export const SelectField = styled(MaterialTextField)`
  margin-top: 2px;
  margin-bottom: 8px;
`;

export const FormLabel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 6px;
`;
