import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import LocalFloristIcon from "@material-ui/icons/LocalFlorist";
import styled from "styled-components";
import { Link, animateScroll as scroll } from "react-scroll";
import Hidden from "@material-ui/core/Hidden";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: calc(10px + 2vmin);
  min-height: 10vh;
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: white;
`;

export default function Header(props) {
  const { sections } = props;
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <HeaderContainer>
      <div>
        <IconButton
          edge="start"
          color="primary"
          onClick={scrollToTop}
          style={{ marginLeft: 2 }}
        >
          <LocalFloristIcon style={{ marginRight: 4 }} />
          <Typography variant="h4">New Support</Typography>
        </IconButton>
      </div>
      <div style={{ marginRight: 8 }}>
        <Hidden smDown>
          {sections.map((section) => (
            <Link
              key={section.id}
              to={section.id}
              activeClass="active"
              spy={true}
              smooth={true}
              duration={600}
              offset={-70}
            >
              <Button>{section.name}</Button>
            </Link>
          ))}
          <Link to="contact" smooth={true} duration={600} offset={-70}>
            <Button
              color="primary"
              variant="contained"
              style={{ marginLeft: 10 }}
            >
              お問い合わせ
            </Button>
          </Link>
        </Hidden>
      </div>
    </HeaderContainer>
  );
}
