import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./components/theme";
import Container from "@material-ui/core/Container";
import {
  Typography,
  Box,
  Button,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { Link as ScrollLink } from "react-scroll";
import {
  FirstView,
  SectionDiv,
  TopLeftSide,
  TopImage,
  WhatIsSection,
  AboutSection,
  FlowSection,
  UseCaseSection,
  AboutImage,
  FlowImage,
  WhatIsContent,
} from "./components/styled";
import { introductionExamples, introductionFields } from "./text";
import Contact from "./components/Contact";
import Service from "./components/Service";
import Price from "./components/Price";
import Footer from "./components/Footer";
import ReactGA from "react-ga";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  sectionTitle: {
    paddingLeft: theme.spacing(1),
    borderWidth: 8,
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));
function App() {
  const classes = useStyles();

  const [contactType, setContactType] = useState("レンタル希望");

  const contactTypes = ["レンタル希望", "レンタル検討中", "その他お問い合わせ"];

  const sections = [
    {
      id: "about",
      name: "InBodyについて",
    },
    {
      id: "service",
      name: "サービス",
    },

    {
      id: "price",
      name: "料金",
    },
    {
      id: "flow",
      name: "ご利用までの流れ",
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header sections={sections} />
        <Container maxWidth="lg" className={classes.root}>
          <FirstView>
            <TopLeftSide>
              <Typography variant="h1" className={classes.text}>
                Rental InBody
              </Typography>
              <Typography variant="h5" className={classes.text}>
                九州中四国では初のInBodyレンタル代理店です。
                <br />
                送料を抑えて、他社よりお安くサービスをご提供できます。
                <br />
                専門家によるサポートも行っておりますので、
                <br />
                初めての方でも安心してご利用いただけます。
              </Typography>
              <ScrollLink
                to="contact"
                smooth={true}
                duration={600}
                offset={-50}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  レンタルする
                </Button>
              </ScrollLink>
            </TopLeftSide>
            <TopImage src="./images/26658.jpg"></TopImage>
          </FirstView>
          <WhatIsSection>
            <WhatIsContent>
              <Typography variant="h1" className={classes.text}>
                InBodyって？
              </Typography>
              <Typography
                variant="h5"
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                }}
              >
                InBodyとは、体成分分析装置です。
                <br />
                InBodyで、体の詳細なデータを測定することができます。　
                <br />
                面倒な操作は必要ありません。
                <br />
                組み立てや設置に道具が必要なく、導入の手間がかかりません。
                <br />
                大事な、測定結果のみに集中していただけます。
              </Typography>
            </WhatIsContent>
          </WhatIsSection>
          <SectionDiv id="about">
            <AboutSection>
              <AboutImage src="./images/270-side.jpg"></AboutImage>
              <div>
                <Typography variant="h1" gutterBottom className={classes.text}>
                  InBodyで体内測定
                </Typography>
                <Typography variant="h5" gutterBottom className={classes.text}>
                  InBodyを使って、健康状態をチェックしませんか？
                  <br />
                  最新の科学技術を用いることで、
                  <br />
                  簡単、迅速、正確な測定を実現します。
                  <br />
                  もっと詳しく知りたい方はこちらへ。
                </Typography>
                <Button variant="outlined" color="primary">
                  <Link href="https://www.inbody.co.jp/" underline="none">
                    公式サイトへ
                  </Link>
                </Button>
              </div>
            </AboutSection>
          </SectionDiv>

          {/* サービス */}
          <SectionDiv id="service">
            <Service />
          </SectionDiv>
          {/* 導入実績 */}
          <Box
            borderColor="primary.main"
            borderLeft={1}
            className={classes.sectionTitle}
          >
            <Typography
              variant="h2"
              gutterBottom
              className={classes.sectionTitle}
            >
              ご利用事例
            </Typography>
          </Box>
          <UseCaseSection>
            <ExpansionPanel style={{ marginTop: 30 }}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" gutterBottom>
                  導入分野・業種
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <List>
                  {introductionFields.map((field) => (
                    <ListItem key={field}>
                      <ListItemText>{field}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel style={{ marginTop: 15 }}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" gutterBottom>
                  導入事例・実績
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <List>
                  {introductionExamples.map((example) => (
                    <ListItem key={example.primary}>
                      <ListItemText
                        primary={example.primary}
                        secondary={example.secondary}
                      />
                    </ListItem>
                  ))}
                </List>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </UseCaseSection>
          {/* 料金 */}
          <SectionDiv id="price">
            <Price />
          </SectionDiv>
          <SectionDiv id="flow">
            <Box
              borderColor="primary.main"
              borderLeft={1}
              className={classes.sectionTitle}
            >
              <Typography
                variant="h2"
                gutterBottom
                className={classes.sectionTitle}
              >
                ご利用までの流れ
              </Typography>
            </Box>
            <FlowSection>
              <FlowImage src="./images/useFlow.png"></FlowImage>
            </FlowSection>
          </SectionDiv>

          {/* お問い合わせ */}
          <SectionDiv id="contact">
            <Contact />
          </SectionDiv>

          {/* footer */}
          <Footer />
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default App;
