import React, { Fragment } from "react";
import { Typography, Divider as MaterialDivider } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import styled from "styled-components";
import InstagramIcon from "@material-ui/icons/Instagram";
import LaunchIcon from "@material-ui/icons/Launch";
const FooterDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
`;
const TwitterIconDiv = styled.div`
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
`;
const FacebookIconDiv = styled.div`
  background-color: #fff;

  border-radius: 4px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
`;
const InstagramIconDiv = styled.div`
  /* background: #d6249f; */
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
`;
const CopyrightDiv = styled.div`
  text-align: center;
  margin-top: 6px;
`;
const FooterContentDiv = styled.div`
  margin: 0 auto;
`;
const Divider = styled(MaterialDivider)`
  margin: 28px 0;
`;
const SNSLink = styled.a`
  color: #fff;
`;
const FacebookLink = styled.a`
  color: #28589c;
`;
export default function Footer() {
  return (
    <Fragment>
      <Divider />
      <FooterDiv>
        <FooterContentDiv>
          <Typography variant="h4" style={{ marginBottom: 12 }}>
            会社概要
          </Typography>
          <a
            href="https://www.newsupport.co.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              variant="body1"
              style={{
                fontSize: 16,
                display: "inline-block",
                color: "black",
              }}
            >
              (株)New Support
            </Typography>
            <LaunchIcon
              style={{
                fontSize: 14,
                display: "inline-block",
                marginLeft: 6,
                color: "#454545",
                verticalAlign: "middle",
              }}
            />
          </a>
          <Typography variant="body1" style={{ marginTop: 12 }}>
            〒819-0373
          </Typography>
          <Typography variant="body1">
            福岡県福岡市西区大字周船寺172-3
          </Typography>
          <Typography variant="body1">TEL:092-834-5439</Typography>
        </FooterContentDiv>

        <FooterContentDiv>
          <TwitterIconDiv>
            <SNSLink href="https://twitter.com/koki_shionew">
              <TwitterIcon
                style={{
                  backgroundColor: "#1DA1F2",
                  display: "table-cell",
                  verticalAlign: "middle",
                  padding: 2,
                  borderRadius: 4,
                }}
              />
            </SNSLink>
          </TwitterIconDiv>
          <FacebookIconDiv>
            <FacebookLink href="https://www.facebook.com/itocafe.arata/">
              <FacebookIcon
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  fontSize: 36,
                }}
              />
            </FacebookLink>
          </FacebookIconDiv>
          <InstagramIconDiv>
            <SNSLink href="https://www.instagram.com/new_support/">
              <InstagramIcon
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  padding: 2,
                  borderRadius: 4,
                }}
              />
            </SNSLink>
          </InstagramIconDiv>
        </FooterContentDiv>
      </FooterDiv>
      <Divider style={{ marginBottom: 0 }} />
      <CopyrightDiv>
        <Typography variant="caption">
          Copyright ©2020 by New Support Inc. All rights reserved.
        </Typography>
      </CopyrightDiv>
    </Fragment>
  );
}
