import { ContactSection, InputFieldDiv, TextField } from "./styled";
import {
  Button,
  Box,
  Typography,
  FormLabel,
  Dialog,
  DialogContent,
  Divider,
  DialogTitle,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PrivacyPolicy from "./PrivacyPolicy";
import styled from "styled-components";
const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    paddingLeft: theme.spacing(1),
    borderWidth: 8,
  },
}));
const FeedbackDiv = styled.div`
  text-align: center;
`;
export default function Contact(props) {
  const { register, handleSubmit, errors } = useForm();
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [formFeedBackOpen, setFormFeedBackOpen] = useState(false);
  const classes = useStyles();
  const handlePrivacyPolicyOpen = () => {
    setPrivacyPolicyOpen(true);
  };

  const handlePrivacyPolicyClose = () => {
    setPrivacyPolicyOpen(false);
  };
  const handleFormFeedBackOpen = () => {
    setFormFeedBackOpen(true);
  };
  const handleFormFeedBackClose = () => {
    setFormFeedBackOpen(false);
  };

  const onSubmit = async (data) => {
    const url =
      "https://00yh392px3.execute-api.us-east-1.amazonaws.com/dev/user";
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    if (Object.entries(errors).length === 0) {
      handleFormFeedBackOpen();
    }
  };
  return (
    <Fragment>
      <Box
        borderColor="primary.main"
        borderLeft={1}
        className={classes.sectionTitle}
      >
        <Typography variant="h2" gutterBottom className={classes.sectionTitle}>
          お問合せ
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ContactSection>
          <InputFieldDiv>
            <FormLabel>
              <Typography variant="h4">会社名</Typography>
              {errors.name && (
                <Typography
                  variant="body2"
                  style={{
                    color: "red",
                  }}
                >
                  入力してください。
                </Typography>
              )}
            </FormLabel>
            <TextField
              error={!!errors.name}
              name="name"
              type="text"
              variant="outlined"
              fullWidth
              autoFocus={false}
              inputRef={register({ required: true })}
            />
          </InputFieldDiv>

          <InputFieldDiv>
            <FormLabel>
              <Typography variant="h4">メールアドレス</Typography>
              {errors.email && errors.email.type === "required" && (
                <Typography
                  variant="body2"
                  style={{
                    color: "red",
                  }}
                >
                  入力してください。
                </Typography>
              )}
              {errors.email &&
                errors.email.type === "pattern" &&
                errors.email.type !== "required" && (
                  <Typography
                    variant="body2"
                    style={{
                      color: "red",
                    }}
                  >
                    不正なメールアドレスです。
                  </Typography>
                )}
            </FormLabel>
            <TextField
              error={!!errors.email}
              autoComplete="email"
              name="email"
              type="email"
              variant="outlined"
              fullWidth
              autoFocus={false}
              inputRef={register({
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
          </InputFieldDiv>
          <InputFieldDiv>
            <FormLabel>
              <Typography variant="h4">お問い合わせ内容</Typography>
              {errors.text && errors.text.type === "required" && (
                <Typography
                  variant="body2"
                  style={{
                    color: "red",
                  }}
                >
                  入力してください。
                </Typography>
              )}
            </FormLabel>
            <TextField
              name="text"
              multiline
              rows="5"
              fullWidth
              variant="outlined"
              autoFocus={false}
              inputRef={register({ required: true })}
            />
          </InputFieldDiv>
          <Button onClick={handlePrivacyPolicyOpen} size="small">
            プライバシーポリシー
          </Button>
          <Dialog onClose={handlePrivacyPolicyClose} open={privacyPolicyOpen}>
            <DialogTitle>
              <Typography variant="h3">プライバシーポリシー</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <PrivacyPolicy />
            </DialogContent>
          </Dialog>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            style={{
              marginTop: 16,
              paddingLeft: 48,
              paddingRight: 48,
            }}
          >
            送信
          </Button>
          <Dialog onClose={handleFormFeedBackClose} open={formFeedBackOpen}>
            <DialogContent
              style={{
                paddingTop: 48,
                paddingBottom: 48,
                paddingRight: 24,
                paddingLeft: 24,
              }}
            >
              <FeedbackDiv>
                <Typography variant="h5">
                  【お問い合わせを受け付けました。】
                </Typography>
              </FeedbackDiv>
              <div style={{ marginTop: 24 }}>
                <Typography variant="body1" style={{ marginBottom: 12 }}>
                  お問い合わせありがとうございます。
                </Typography>
                <Typography variant="body1">
                  3日以内に返信がない場合、システムの不具合の可能性がございます。
                </Typography>
                <Typography variant="body1">
                  その際はお手数ですが、お電話にてご連絡を頂けますと幸いです。
                </Typography>
              </div>
            </DialogContent>
          </Dialog>
        </ContactSection>
      </form>
    </Fragment>
  );
}
