export const introductionExamples = [
    {
        primary: "医療機関での健康イベント",
        secondary: "レンタル日数：１日 測定人数：200名"
    },
    {
        primary: "専門学校のオープンキャンパスでの測定体験",
        secondary: "レンタル日数：２日 測定人数：120名"
    },
    {
        primary: "大学での研究利用",
        secondary: "レンタル日数：毎週１回 測定人数：40名/回"
    },
    {
        primary: "デイサービスでの機能訓練評価",
        secondary: "レンタル日数：毎月3日間 測定人数：90名/3日間"
    },
    {
        primary: "大型商業施設での従業員向け測定",
        secondary: "レンタル日数：２ヶ月間に１回１日 測定人数：60名/回"
    },
    {
        primary: "カーディーラーでの健康イベント",
        secondary: "レンタル日数：毎月１回2日間 測定人数：80名/2日間"
    },
    {
        primary: "プロ野球選手自主トレ期間",
        secondary: "レンタル日数：２ヶ月間 測定人数：2 〜10名/日"
    },
    {
        primary: "大相撲力士 地方場所期間",
        secondary: "レンタル日数：年間３回 各２週間 測定人数：3〜10名/日"
    },
    {
        primary: "高校野球チーム定期測定",
        secondary: "レンタル日数：３ヶ月に１回 測定人数：50名/回"
    },
    {
        primary: "運送業の健康管理",
        secondary: "レンタル日数：毎月5日間 測定人数：160名"
    },
    {
        primary: "公民館での健康イベント",
        secondary: "レンタル日数：年間６回 測定人数：100名/回"
    }
];
export const introductionFields = [
    "医療：病院、クリニック",
    "教育：大学、高校、専門学校、学習塾",
    "介護福祉：デイサービス、老人ホーム、発達支援施設",
    "スポーツ：プロ野球選手、プロサッカーチーム、大相撲力士、各種ジュニアスポーツチーム、部活動",
    "産業：運送業（バス・トラック・タクシー）、廃棄業、建設、食品加工工場、製造工場",
    "地域：公民館、社会福祉協議会、自治協議会"
];
