import React, { Fragment } from "react";
import FaceIcon from "@material-ui/icons/Face";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PlaceIcon from "@material-ui/icons/Place";
import { Box, Typography, Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ServiceSection } from "./styled";
const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    paddingLeft: theme.spacing(1),
    borderWidth: 8,
  },
}));
export default function Service() {
  const classes = useStyles();
  return (
    <Fragment>
      <Box
        borderColor="primary.main"
        borderLeft={1}
        className={classes.sectionTitle}
      >
        <Typography variant="h2" gutterBottom className={classes.sectionTitle}>
          サービス
        </Typography>
      </Box>

      <ServiceSection>
        <Card elevation={0} className={classes.serviceCard}>
          <CardContent>
            <div
              style={{
                textAlign: "center",
                marginBottom: 30,
              }}
            >
              <PlaceIcon color="primary" style={{ fontSize: 120 }} />
            </div>
            <div>
              <Typography gutterBottom variant="h3" component="h2">
                九州中四国ではここだけ
              </Typography>
            </div>
            <div>
              <Typography gutterBottom variant="body1" component="p">
                私たちは、九州中四国では唯一の
                <br />
                InBodyレンタルサービス提供者です。
                <br />
                これまで他県からレンタルされていた方へ
                <br />
                お安く、早くサービスをご提供できます。
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card elevation={0} className={classes.serviceCard}>
          <CardContent>
            <div
              style={{
                textAlign: "center",
                marginBottom: 30,
              }}
            >
              <FaceIcon color="primary" style={{ fontSize: 120 }} />
            </div>
            <div>
              <Typography gutterBottom variant="h3" component="h2">
                 同行サービス
              </Typography>
            </div>
            <div>
              <Typography gutterBottom variant="body1" component="p">
                初めての方でも安心して使用していただけるように、
                <br />
                わたしたちスタッフが同行し、
                <br />
                お客様の測定をサポートいたします。
                <br />
                ※同行プランを選択されたお客様
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card elevation={0} className={classes.serviceCard}>
          <CardContent>
            <div
              style={{
                textAlign: "center",
                marginBottom: 30,
              }}
            >
              <MenuBookIcon color="primary" style={{ fontSize: 120 }} />
            </div>
            <div>
              <Typography gutterBottom variant="h3" component="h2">
                健康コンサルティング
              </Typography>
            </div>
            <div>
              <Typography gutterBottom variant="body1" component="p">
                測定結果を基に、看護師や理学療法士が
                <br />
                栄養指導、運動指導を行います。
              </Typography>
            </div>
          </CardContent>
        </Card>
      </ServiceSection>
    </Fragment>
  );
}
